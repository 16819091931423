<template>
    <!-- 修改密码 -->
    <div class="my-password">
        <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
        >
            <el-form-item label="原密码" prop="Ypass">
                <el-input
                    type="password"
                    v-model="ruleForm.Ypass"
                    autocomplete="off"
                    placeholder="请输入原密码"
                ></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="pass">
                <el-input
                    type="password"
                    v-model="ruleForm.pass"
                    autocomplete="off"
                    placeholder="密码必须包含数字和字母且不少于8位"
                ></el-input>
            </el-form-item>
            <el-form-item label="确认新密码" prop="checkPass">
                <el-input
                    type="password"
                    v-model="ruleForm.checkPass"
                    autocomplete="off"
                    placeholder="请输入确认新密码"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')"
                    >提交</el-button
                >
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'my-password',
    components: {},
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'))
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass')
                }
                callback()
            }
        }
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入新密码'))
            } else if (value !== this.ruleForm.pass) {
                callback(new Error('两次输入新密码不一致!'))
            } else {
                callback()
            }
        }
        var validatePass3 = (rule, value, callback) => {
            var reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
            if (value === '') {
                callback(new Error('请输入新密码'))
            }else if(value === this.ruleForm.Ypass){
                    callback(new Error('新密码不能和原密码一致!'))
            }else if(value.length < 8){
                    callback(new Error('新密码不能少于8位!'))
            }else if(!reg.test(value)){
                    callback(new Error('新密码必须英文加数字!'))
            } else{ 
                callback()
            }
        }
        return {
            ruleForm: {
                Ypass: '',
                pass: '',
                checkPass: '',
            },
            rules: {
                Ypass: [{ validator: validatePass, trigger: 'blur' }],
                pass: [{ validator: validatePass3, trigger: 'blur' }],
                checkPass: [{ validator: validatePass2, trigger: 'blur' }],
            },
        }
    },
    created() {},
    methods: {
        // 提交
        submitForm(formName) {
            let value = {
                pwdNew: this.ruleForm.pass,
                pwdNewConfirmed: this.ruleForm.checkPass,
                pwdOriginal: this.ruleForm.Ypass,
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.postPassword(value)
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        postPassword(value) {
            this.$instance
                .post('/v1/admin-user/pwd-modify', value)
                .then((res) => {
                    // console.log('res: ', res.data)
                    if (res.data.status === 200) {
                        this.$message({
                            message: '修改密码成功！',
                            type: 'success',
                        })
                        this.$router.push('/login')
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.my-password {
    width: calc(100% - 40px);
    display: flex;
    padding: 80px 60px;
    margin: 20px;
    background-color: #fff;
    box-sizing: border-box;
    & ::v-deep .el-form {
        width: 520px;
        .el-form-item {
            .el-button--primary {
                width: 120px;
            }
        }
    }
}
</style>
